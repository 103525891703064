import { Modal } from 'assets/components/modal';
import { TextField } from 'assets/components/text-field';
import { getSpacing, theme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { FormProvider, useForm } from 'assets/form';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect, useState } from 'react';
import { UserForm } from './UserTypes';
import { UserModalTestIDs } from './UserModalTestIDs';
import { UseFormReturn } from 'react-hook-form';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { Role } from '@digitalpharmacist/role-service-client-axios';
import { StyleSheet, View } from 'react-native';
import { UserRolesModal } from './UserRolesModal';
import { useAppStateStore } from '../../../store/app-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import PharmacyService from '../../../api/PharmacyService';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { updateCreateUser } from './UserActions';
import { locationRoleOptions } from './UserHelpers';

export const UserModal: FunctionComponent<UserModalProps> = ({
  title,
  onSubmit,
  showModal,
  setShowModal,
  methods,
}) => {
  const [showUserRolesModal, setShowUserRolesModal] = useState<boolean>(false);
  const { pharmacyId } = useAppStateStore();
  const [locationCount, setLocationCount] = useState<number>(0);
  const [singleLocation, setSingleLocation] = useState<string>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const roleOptionList = [
    ...locationRoleOptions(),
    {
      label: 'Per Location',
      value: 'PER_LOCATION',
    },
  ];

  useEffect(() => {
    (async () => {
      const result = await PharmacyService.pharmacyLocationFindAll();
      const locations: PharmacyLocationDto[] = result.locations;

      setLocationCount(locations.length);

      if (locations.length === 1) {
        setSingleLocation(locations[0].id);
      }
    })();
  }, [pharmacyId]);

  useEffect(() => {
    if (!showUserRolesModal) {
      const roles = methods.getValues().roles;
      let initialValue: Role | undefined = undefined;
      let hasOneRole = true;
      if (
        locationCount > 1 &&
        roles &&
        Object.keys(roles).length === locationCount
      ) {
        for (const key in roles) {
          if (!initialValue) {
            initialValue = roles[key];
          } else if (initialValue !== roles[key]) {
            hasOneRole = false;
            break;
          }
        }
      } else {
        hasOneRole = false;
      }
      if (hasOneRole) {
        methods.setValue('role', initialValue);
      } else {
        methods.setValue('role', 'PER_LOCATION');
      }
    }
  }, [showUserRolesModal]);

  const showRolesModal = () => {
    setShowModal(false);
    setShowUserRolesModal(true);
  };

  const close = () => {
    setShowModal(false);
    methods.reset();
  };

  const handleSubmit = async (userForm: UserForm) => {
    try {
      setShowLoading(true);
      await updateCreateUser(userForm);
      setShowModal(false);
      onSubmit();
    } catch (err) {
      if (err === 'Conflict') {
        // The <Alert> component doesn't work here for some reason. Maybe because its a modal
        alert('This email already exists');
      } else {
        alert(err);
      }
    }
    setShowLoading(false);
  };

  return (
    <>
      {showUserRolesModal ? (
        <UserRolesModal
          setShowUserModal={setShowModal}
          showModal={showUserRolesModal}
          setShowModal={setShowUserRolesModal}
          methods={methods}
        ></UserRolesModal>
      ) : null}
      <Modal
        title={title}
        cancelButtonProps={{
          onPress: close,
          logger: { id: 'user-edit-cancel-button' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'user-edit-confirm-button' },
          hierarchy: 'pharmacy-primary',
          text: 'Ok',
        }}
        show={showModal}
        size="sm"
      >
        {showLoading ? <LoadingOverlay /> : <></>}
        <FormProvider {...methods}>
          <View style={styles.twoColumnRow}>
            <View style={styles.column}>
              <TextField
                label={getText('first-name')}
                name="firstName"
                rules={{
                  required: getText('first-name-is-required'),
                }}
                disabled={status === 'loading'}
                testID={UserModalTestIDs.firstNameInput}
              />
            </View>
            <View style={styles.column}>
              <TextField
                label={getText('last-name')}
                name="lastName"
                rules={{
                  required: getText('last-name-is-required'),
                }}
                disabled={status === 'loading'}
                testID={UserModalTestIDs.lastNameInput}
              />
            </View>
          </View>
          <View style={styles.row}>
            <TextField
              label={getText('email')}
              name="email"
              rules={{
                required: getText('email-is-required'),
              }}
              disabled={status === 'loading'}
              testID={UserModalTestIDs.emailInput}
            />
          </View>
          <View style={styles.twoColumnRow}>
            <View style={styles.column}>
              {locationCount > 1 ? (
                <DropdownSelect
                  fieldName="role"
                  label={getText('role')}
                  options={roleOptionList}
                  disabled={true}
                />
              ) : (
                <DropdownSelect
                  fieldName={`roles.${singleLocation}`}
                  label={'Role'}
                  options={roleOptionList}
                />
              )}
            </View>
            <View style={styles.column}>
              {locationCount > 1 && (
                <Text style={styles.showLocations} onPress={showRolesModal}>
                  {getText('show-locations')}
                </Text>
              )}
            </View>
          </View>
        </FormProvider>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  twoColumnRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    flex: 50,
    margin: getSpacing(1),
  },
  row: {
    margin: getSpacing(1),
  },
  showLocations: {
    weight: '500',
    color: theme.palette.primary[600],
  },
});

export interface UserModalProps {
  title: string;
  onSubmit: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn<UserForm, any>;
}
