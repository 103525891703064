import { IMessage, User } from 'react-native-gifted-chat';
import {
  DirectMessagePatientDto,
  MessageTemplateDto,
  AuthorType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useInboxState } from './inbox-store';
import {
  BaseFilterValues,
  EmittedMessage,
  MultiFilterValues,
  MessagesPagination,
  IComposedAttachments,
  IMessageExtended,
  EmittedUpdatedUserStatus,
  TextFilters,
  IConversationsSorting,
} from '../types';
import {
  PatientUserDto,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { compare, groupConversationsByPatient } from '../utils';

export const setPatients = (patients: DirectMessagePatientDto[]) => {
  useInboxState.setState({ patients: patients });
};

export const setSelectedPatient = (
  selectedPatient: LocationPatientRecordDto,
) => {
  useInboxState.setState({ selectedPatient: selectedPatient });
};

export const removeSelectedPatient = () => {
  useInboxState.setState({ selectedPatient: null });
};

export const setSelectedConversation = (selectedConversation: string) => {
  useInboxState.setState({ selectedConversation: selectedConversation });
};

export const removeSelectedConversation = () => {
  useInboxState.setState({ selectedConversation: null });
};

export const setViewedConversations = (viewedConversations: string[]) => {
  useInboxState.setState({ viewedConversations: viewedConversations });
};

export const setConversationsSorting = (
  conversationsSorting: IConversationsSorting,
) => {
  useInboxState.setState({ conversationsSorting: conversationsSorting });
};

export const setMessagesGroups = (
  messagesGroups: DirectMessagePatientDto[],
) => {
  useInboxState.setState({ messagesGroups: messagesGroups });
};

export const setSelectedMessages = (selectedMessages: IMessage[]) => {
  useInboxState.setState({ selectedMessages: selectedMessages });
};

export const removeSelectedMessages = () => {
  useInboxState.setState({ selectedMessages: [] });
};

export const setRawConversations = (
  rawConversations: DirectMessagePatientDto[],
) => {
  useInboxState.setState({ rawConversations: rawConversations });
};

export const openNewChatModal = () => {
  useInboxState.setState({ isOpenNewChatModal: true });
};

export const closeNewChatModal = () => {
  useInboxState.setState({ isOpenNewChatModal: false });
};

export const setTemplates = (templates: MessageTemplateDto[]) => {
  useInboxState.setState({ templates: templates });
};

export const setIncomeMessage = (
  message: EmittedMessage,
  author: PharmacyUserDto | PatientUserDto | undefined,
  composedAttachments?: IComposedAttachments,
) => {
  const { rawConversations, selectedConversation, conversationsSorting } =
    useInboxState.getState();

  useInboxState.setState((state) => {
    const isMessageNew = !state.selectedMessages.find(
      (item) => message.id === item._id,
    );

    const patientIndex = state.patients.findIndex((patient) => {
      return message.location_patient_id == patient.location_patient_id;
    });
    let newPatients: DirectMessagePatientDto[] = [];
    if (patientIndex > -1) {
      newPatients = [...state.patients];

      newPatients[patientIndex] = {
        ...newPatients[patientIndex],
        pharmacy_viewed_all_messages: !isMessageNew,
        most_recent_qualifying_message: message.content,
        most_recent_qualifying_message_date: message.created_at.toString(),
      };
    }

    let newSelectedMessages: IMessage[] = [];
    if (isMessageNew && state.selectedConversation == message.conversation_id) {
      const user: User = { _id: message.author_id };
      if (author) {
        user.name = author.firstName + ' ' + author.lastName;
      }
      const newMessage: IMessageExtended = {
        _id: message.id,
        createdAt: new Date(message.created_at),
        text: message.content,
        user,
        attachments: composedAttachments ?? { files: [], images: [] },
      };

      newSelectedMessages = [...state.selectedMessages, newMessage];
    }

    const newState = {
      ...state,
    };

    if (newSelectedMessages.length > 0) {
      newState.selectedMessages = newSelectedMessages;
    }

    if (newPatients.length > 0) {
      newState.patients = newPatients;
    }

    const rawConversationsData = [...rawConversations];
    const currentConversationIndex = rawConversationsData.findIndex(
      (conversation) => {
        return conversation.conversation_id === message.conversation_id;
      },
    );

    const currentConversation = rawConversationsData[currentConversationIndex];
    if (
      currentConversationIndex !== -1 &&
      selectedConversation !== message.conversation_id &&
      message.author_type === AuthorType.Patient
    ) {
      currentConversation.pharmacy_viewed_all_messages = false;
    }

    currentConversation.most_recent_qualifying_message = message.content;
    currentConversation.most_recent_qualifying_message_date =
      message.created_at.toString();

    const patientsData = groupConversationsByPatient(rawConversationsData).sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
    );

    const newViewedConversations = rawConversationsData
      .filter((conversation) => conversation.pharmacy_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    newState.rawConversations = rawConversationsData;
    newState.viewedConversations = newViewedConversations;
    newState.patients = patientsData;

    return newState;
  });
};

export const setUpdatedUserStatus = (
  updatedUserStatus: EmittedUpdatedUserStatus,
) => {
  useInboxState.setState((state) => {
    const newState = { ...state };

    const viewedConversations = newState.viewedConversations;

    const index = viewedConversations.indexOf(
      updatedUserStatus.conversation_id,
    );
    if (updatedUserStatus.pharmacy_viewed_all_messages && index === -1) {
      newState.viewedConversations = [
        ...viewedConversations,
        updatedUserStatus.conversation_id,
      ];
    } else if (
      !updatedUserStatus.pharmacy_viewed_all_messages &&
      index !== -1
    ) {
      viewedConversations.splice(index, 1);
      newState.viewedConversations = viewedConversations;
    }

    const rawConversationsData = [...newState.rawConversations];
    const currentConversationIndex = rawConversationsData.findIndex(
      (conversation) => {
        return (
          conversation.conversation_id === updatedUserStatus.conversation_id
        );
      },
    );

    const currentConversation = rawConversationsData[currentConversationIndex];
    currentConversation.pharmacy_viewed_all_messages =
      updatedUserStatus.pharmacy_viewed_all_messages;

    const patientsData = groupConversationsByPatient(rawConversationsData).sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          newState.conversationsSorting.field,
          newState.conversationsSorting.order,
          newState.conversationsSorting.isDate,
        ),
    );

    newState.rawConversations = rawConversationsData;
    newState.patients = patientsData;

    return newState;
  });
};

export const setNewConversation = (
  conversation: DirectMessagePatientDto,
  author: PharmacyUserDto | PatientUserDto | undefined,
) => {
  useInboxState.setState((state) => {
    const newState = { ...state };
    if (conversation.location_patient_id === state.selectedPatient?.id) {
      const foundConversation = newState.messagesGroups.find(
        (item) => item.conversation_id === conversation.conversation_id,
      );

      if (newState.selectedConversation === conversation.conversation_id) {
        const user: User = { _id: conversation.original_author_id };
        if (author) {
          user.name = author.firstName + ' ' + author.lastName;
        }
        const message: IMessage = {
          _id: `new-message-${Date.now()}`,
          text: conversation.most_recent_qualifying_message,
          createdAt: new Date(conversation.most_recent_qualifying_message_date),
          user,
        };
        newState.selectedMessages = [...newState.selectedMessages, message];
      } else if (!foundConversation) {
        const newMessagesGroups = [conversation, ...state.messagesGroups];
        newState.messagesGroups = newMessagesGroups;
      }
    }
    return newState;
  });
};

export const setNewPatient = (patient: DirectMessagePatientDto) => {
  useInboxState.setState((state) => {
    return {
      ...state,
      patients: [patient, ...state.patients],
    };
  });
};

export const setBaseFilter = (filter: BaseFilterValues) => {
  useInboxState.setState({
    baseFilter: filter,
  });
};

export const setMultiFilters = (filter: MultiFilterValues) => {
  const { multiFilters } = useInboxState.getState();

  if (filter === MultiFilterValues.NO_FILTERS) {
    useInboxState.setState({
      multiFilters: [],
    });
  } else if (multiFilters.includes(filter)) {
    useInboxState.setState({
      multiFilters: multiFilters.filter((filterName) => filter !== filterName),
    });
  } else {
    useInboxState.setState({
      multiFilters: [...multiFilters, filter],
    });
  }
};

export const disableFilters = () => {
  useInboxState.setState({ disabled: true });
};

export const enableFilters = () => {
  useInboxState.setState({ disabled: false });
};

export const setCounts = (count: { unread: number }) => {
  useInboxState.setState((state) => {
    const newState = { ...state };
    if (count.unread > 0) {
      return { ...newState, count };
    }
    return { ...state, count: {} };
  });
};

export const resetFilters = () => {
  useInboxState.setState({
    baseFilter: BaseFilterValues.ALL,
    multiFilters: [],
  });
};

export const setPharmacyHasPatientRecords = (
  pharmacyHasPatientRecords: boolean,
) => {
  useInboxState.setState({
    pharmacyHasPatientRecords: pharmacyHasPatientRecords,
  });
};

export const setMessagesPagination = (pagination: MessagesPagination) => {
  useInboxState.setState({ messagesPagination: pagination });
};

export const setShowAddPatientModal = (showAddPatientModal: boolean) => {
  useInboxState.setState({ showAddPatientModal: showAddPatientModal });
};

export const setShowEditModal = (showEditModal: boolean) => {
  useInboxState.setState({ showEditModal: showEditModal });
};

export const setRawConversationsCount = (rawConversationsCount: number) => {
  useInboxState.setState({ rawConversationsCount: rawConversationsCount });
};

export const setTextFilters = (textFilters: TextFilters) => {
  useInboxState.setState({ textFilters: textFilters });
};
