import { Modal } from 'assets/components/modal';
import { getSpacing, theme } from 'assets/theme';
import { FormProvider } from 'assets/form';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect, useState } from 'react';
import { UserForm } from './UserTypes';
import { UseFormReturn } from 'react-hook-form';
import { DropdownSelect } from 'assets/components/dropdown-select';
import {
  LocationRole,
  Role,
} from '@digitalpharmacist/role-service-client-axios';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { DataGrid } from 'assets/components/data-grid';
import { StyleSheet, View, Text } from 'react-native';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import PharmacyService from '../../../api/PharmacyService';
import { useAppStateStore } from '../../../store/app-store';
import { Avatar } from 'assets/components/avatar';
import { locationRoleOptions } from './UserHelpers';

type UserRolesRow = {
  locationName: string;
  locationId: string;
  state: string;
  city: string;
  role?: Role;
};

export const UserRolesModal: FunctionComponent<UserRolesModalProps> = ({
  setShowUserModal,
  showModal,
  setShowModal,
  methods,
}) => {
  const [rowData, setRowData] = useState<UserRolesRow[]>();
  const [initialRoles, setInitialRoles] =
    useState<Record<string, LocationRole>>();
  const roleOptionList = locationRoleOptions();

  const cancel = () => {
    setShowModal(false);
    setShowUserModal(true);
    setRowData([]);
    methods.setValue('roles', initialRoles);
  };

  const save = () => {
    setShowModal(false);
    setShowUserModal(true);
    setRowData([]);
  };

  useEffect(() => {
    (async () => {
      // This triggers a rerender for when the user selects an
      // applyToAll option. Not sure if there's a more explicit way
      methods.watch('roles');
      const result = await PharmacyService.pharmacyLocationFindAll();
      const locations: PharmacyLocationDto[] = result.locations;

      const userRolesRows: UserRolesRow[] = locations.map((location) => {
        return {
          locationId: location.id,
          locationName: location.name,
          city: location.address?.city!,
          state: location.address?.state!,
        };
      });

      setRowData(userRolesRows);
    })();
    setInitialRoles({ ...methods.getValues().roles });
  }, [showModal]);

  const applyRoleToAll = (role: LocationRole) => {
    rowData?.forEach((row) => {
      methods.setValue(`roles.${row.locationId}`, role);
    });
  };

  const RoleRenderer = (props: { data: UserRolesRow }) => {
    return (
      <View style={styles.rightColumn}>
        <DropdownSelect
          fieldName={`roles.${props.data.locationId}`}
          options={roleOptionList}
          placeholder="No Access"
        />
      </View>
    );
  };

  const NameRenderer = (props: { data: UserRolesRow }) => {
    const userRole = props.data;

    return (
      <View style={styles.leftColumn}>
        <Text>{userRole.locationName}</Text>
        {userRole.city && userRole.state ? (
          <View>
            <Text style={styles.cityState}>
              {userRole.city}, {userRole.state}
            </Text>
          </View>
        ) : null}
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      field: 'locationName',
      cellRenderer: NameRenderer,
    },
    {
      field: 'role',
      cellRenderer: RoleRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  return (
    <Modal
      title="User Roles"
      cancelButtonProps={{
        onPress: cancel,
        logger: { id: 'user-edit-cancel-button' },
      }}
      okButtonProps={{
        logger: { id: 'user-edit-confirm-button' },
        onPress: save,
        hierarchy: 'pharmacy-primary',
        text: 'Ok',
      }}
      show={showModal}
      size="lg"
      isScrollable={true}
    >
      <View style={styles.container}>
        {methods.getValues().firstName &&
          methods.getValues().lastName &&
          methods.getValues().email && (
            <View style={styles.header}>
              <View style={styles.leftHeader}>
                <Text style={styles.name}>
                  {methods.getValues().firstName +
                    ' ' +
                    methods.getValues().lastName}
                </Text>
                <Text style={styles.email}>{methods.getValues().email}</Text>
              </View>
              <View style={styles.rightHeader}>
                <Avatar
                  name={
                    methods.getValues().firstName +
                    ' ' +
                    methods.getValues().lastName
                  }
                  size={40}
                  color={theme.palette.gray[200]}
                />
              </View>
            </View>
          )}

        <FormProvider {...methods}>
          <DataGrid
            isRichContent={true}
            gridOptions={{
              rowData,
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              headerHeight: 0,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              rowClass: 'rich-content',
              paginationPageSize: 25,
              domLayout: 'autoHeight',
              rowHeight: 80,
              loadingOverlayComponent: 'loadingIndicator',
              loadingOverlayComponentParams: {
                color: theme.colors.pharmacyPrimary,
              },
              components: {
                loadingIndicator: LoadingIndicator,
              },
            }}
            gridToolbarProps={{
              inputSearchProps: {
                size: 'lg',
                placeholder: getText('search'),
              },
              actionLinkProps: {
                maxActionToShow: Object.values(LocationRole).length,
                title: 'Apply to all',
                actionLinks: roleOptionList.map((role) => {
                  return {
                    text: role.label,
                    onPress: () => applyRoleToAll(role.value),
                    hierarchy: 'pharmacy-primary',
                    logger: { id: `applyRoleToAll-click-${role.value}` },
                  };
                }),
              },
            }}
          />
        </FormProvider>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rightColumn: {
    marginTop: getSpacing(2),
    textAlign: 'left',
    color: 'black',
  },
  leftColumn: {
    marginTop: getSpacing(2),
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: getSpacing(2),
  },
  leftHeader: {
    flex: 50,
    alignItems: 'flex-start',
  },
  rightHeader: {
    flex: 50,
    alignItems: 'flex-end',
  },
  name: {
    fontSize: 20,
    fontWeight: '600',
    color: theme.palette.gray[900],
    marginBottom: getSpacing(0.5),
  },
  email: {
    color: theme.palette.gray[700],
  },
  cityState: {
    fontSize: 12,
    marginTop: getSpacing(0.5),
    color: theme.palette.gray[700],
  },
});

export interface UserRolesModalProps {
  setShowUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn<UserForm, any>;
  userId?: string;
}
