import React, { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { View, TouchableOpacity } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import { SettingsIcon, MenuIcon, LumistryIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { Avatar } from 'assets/components/avatar';
import { NavigationItems } from './NavigationItems';
import { getText } from 'assets/localization/localization';
import { Menu, MenuItem } from 'react-native-material-menu';

export const PharmacyHeader: FunctionComponent<
  PropsWithChildren<PharmacyHeaderProps>
> = ({
  gradientId = uuid(),
  isUserLoggedIn = false,
  userName,
  navigation,
  screens,
  pharmacyStoreSelector,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const [showMenu, setShowMenu] = React.useState(false);

  const handleMenuItemClick = (navigationPath: string) => () => {
    setShowMenu(false);
    navigation.navigate(navigationPath);
  };

  const menuHoverIn = (event: any) => {
    event.target.style['background-color'] = theme.palette.primary[50];
  };

  const menuHoverOut = (event: any) => {
    event.target.style['background-color'] = '';
  };

  return (
    <>
      <GradientView
        contentHeight={72}
        fillGradientStops={[
          { stopColor: theme.colors.pharmacyPrimary, offset: 0 },
          { stopColor: theme.colors.pharmacyPrimary, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <View style={styles.logo}>
              <LumistryIcon size={50} color={'#fffff'} />
            </View>
            {isUserLoggedIn && navigation && screens && (
              <NavigationItems navigation={navigation} screens={screens} />
            )}
          </View>
          <View style={styles.rightContent}>
            {isUserLoggedIn && userName ? (
              <View style={{ flexDirection: 'row' }}>
                <View>{pharmacyStoreSelector}</View>

                <Menu
                  visible={showMenu}
                  onRequestClose={() => setShowMenu(false)}
                  anchor={
                    <IconButton
                      icon={MenuIcon}
                      logger={{ id: 'pharmacy-menu-icon-button' }}
                      onPress={() => setShowMenu(true)}
                      color={theme.palette.white}
                      testID={PharmacyHeaderTestIDs.menuButton}
                    />
                  }
                  style={styles.hamburgerMenu}
                >
                  <MenuItem
                    onPress={handleMenuItemClick('videos')}
                    onHoverIn={menuHoverIn}
                    onHoverOut={menuHoverOut}
                  >
                    {getText('video-header')}
                  </MenuItem>
                  <MenuItem
                    onPress={handleMenuItemClick('refill-submissions')}
                    onHoverIn={menuHoverIn}
                    onHoverOut={menuHoverOut}
                  >
                    {getText('refill-submissions-header')}
                  </MenuItem>
                </Menu>
                <IconButton
                  icon={SettingsIcon}
                  logger={{ id: 'pharmacy-settings-icon-button' }}
                  onPress={() => navigation.navigate('settings')}
                  color={theme.palette.white}
                  testID={PharmacyHeaderTestIDs.settingsButton}
                />
                <View style={{ marginLeft: theme.getSpacing(1) }}>
                  <TouchableOpacity
                    onPress={() => {}}
                    testID={PharmacyHeaderTestIDs.accountButton}
                  >
                    <Avatar name={userName} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <Text
                style={{
                  color: theme.palette.white,
                }}
              >
                {getText('need-help')}
              </Text>
            )}
          </View>
        </View>
      </GradientView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100%',
    flexDirection: 'row',
    boxShadow: 'inset 0px 2px 8px rgba(0, 0, 0, 0.45)',
  },
  leftContent: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(4),
    flexDirection: 'row',
  },
  rightContent: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'flex-end',
    paddingRight: theme.getSpacing(4),
  },
  navItem: {
    color: theme.palette.white,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  logo: {
    marginRight: theme.getSpacing(3),
  },
  hamburgerMenu: {
    marginTop: 40,
    borderColor: theme.palette.gray[200],
    borderWidth: 1,
  },
}));

export interface PharmacyHeaderProps {
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
  isUserLoggedIn: boolean;
  userName?: string;
  navigation?: any;
  screens?: {
    name: string;
    label: string;
  }[];
  pharmacyStoreSelector?: JSX.Element;
}

export const PharmacyHeaderTestIDs = {
  settingsButton: 'pharmacy-settings-icon-button',
  menuButton: 'pharmacy-menu-icon-button',
  accountButton: 'pharmacy-account-icon-button',
};
